@import url('https://fonts.googleapis.com/css?family=Oleo+Script+Swash+Caps');

$red: #b5181b;
$black: rgb(25, 25, 25);
$borderWidth: 4px;
$paddingY: 0.35em;
$sigFont: 'Oleo Script Swash Caps', Helvetica, sans-serif;
$orange: #E1931F;


.ordering-menu {
  background: #f7f7fc;
  /* background: none; */
  // color: white;
}

// }
.lamaria {
  background: #f7f7fc;

  img.nav-bar-logo {
    display: block;
    // background: #721314;
    // border-radius: 0.3em;
    // padding: 0.3em 0.2em;
    // padding-left: 0.8em;
  }
  .cart.minimized {
    background-color: #f7f7fc;
    background: url("https://d2abh92eaqfsaa.cloudfront.net/image/upload/v1548119243/danstuff/header_pattern_960px_133px_v2.jpg");
    background-position: center bottom;
    padding-bottom: 0.35em;
    border: none;
    /* background: black; */
    // border-bottom: 2px solid rgba(255,255,255,0.8);
  }

  .landing-page, .top-banner {

    padding: 1em 0;
    margin: 0;
    display: block;
    font-family: Helvetica;
    min-height: 150px;
    /* max-height: 50vh; */
    // background: url("http://www.lamariarestaurant.com/images/slideshow-22-940-400.jpg");
    // background: url("http://www.lamariarestaurant.com/images/slideshow-20-940-400.jpg");
    // background: url(https://image.shutterstock.com/z/stock-photo-chinese-food-on-dark-wooden-table-noodles-fried-rice-dumplings-stir-fry-chicken-dim-sum-466836857.jpg);
    // background: url(https://media.istockphoto.com/photos/yellow-table-with-small-jukebox-and-food-in-a-retro-diner-picture-id134100960?s=2048x2048);
    background-size: cover;
    background-position: center 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5em;
  }
  .hero-logo {
    margin: 0 auto;
    max-width: 250px;
    img {
      width: 100%;
    }
  }
  .landing-page .text {
    // font-family: $sigFont;
    a {
      color: white;
    }
    .phone-link {
      font-size: 0.8em;
    }
    text-align: center;
    font-family: Helvetica;
    color: white;
    border-radius: 0.2em;
    background: rgba(0,0,0,1);
    font-weight: 700;
    font-size: 1em;
    padding: 0.5em 0.8em;
    img {
      width: 90%;
      max-width: 700px;
      display: block;
      margin: 0 auto;
    }
  }

  a.checkout-button.button {
    background: $red;
    font-family: $sigFont;
    font-weight: 600;
    box-shadow: none;
    border: 2px solid rgb(0, 0, 0);

  }

  /* .category__header {
  font-family: cursive;

} */
  .category-name .text {
    font-family: $sigFont;
    font-weight: 400;
    font-size: 2.3em;
    color: $red;
    background: none;
    position: relative;
  }

    .top-banner {
      // height: 30vh;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: right center;
      color: white;
      font-weight: 700;
      font-size: 1.5em;
      // text-shadow: 1px 1px 0 $red;
    }

    .pages-nav {
      background: #f7f7fc;
      font-weight: 600;
      font-family: $sigFont;
      // background: #111;
      ul {
        display: flex;
        flex-wrap: wrap;
      }
      @media screen and (max-width: 480px) {
        font-size: 4vw;
      }
    }

    a.page-link {
      text-decoration: none;
      color: black;
      display: block;
      padding: $paddingY 0.3em;
      padding: $paddingY 0.3em calc(#{$paddingY} - #{$borderWidth}) 0.3em;
      // box-sizing: border-box;
      border-bottom: $borderWidth solid #f7f7fc;
      &:hover, &.active {
        border-bottom: $borderWidth solid $orange;
        color: $orange;
      }
    }

    .full-menu-link-wrapper {
      padding: 1em 0;
      text-align: center;
      a.button {
        background: $red;
        color: white;
      }
    }

    .menu-item {
      .food-name {
        // font-family: $sigFont;
        font-size: 1em;
        font-weight: 500;
        // color: $red;
      }
    }

    .add-to-cart-button button {
      font-family: $sigFont;
      border: 2px solid $red;
      // background: $black;
      color: $red;
    }

    .footer {
      background: $orange;
    }

  .menu-header {
    background: #f7f7fc;
    text-align: center;
    padding: 2em 0 0 0;
  }
  .pdf-download-link {
    background: $red;
    color: white;
    text-decoration: none;
    padding: 0.3em 0.8em;
    border-radius: 0.2em;
  }
}
